import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extender day.js con los complementos
dayjs.extend(utc);
dayjs.extend(timezone);

// Función para obtener la fecha de expiración
function getItemExpiry(key) {
    const itemStr = localStorage.getItem(key);

    // Si el ítem no existe, retornar null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    return item.expiry;
}

// Función para guardar un ítem con tiempo de expiración
function setItemWithExpiration(key, value, ttl) {
    const now = dayjs(); // Fecha actual
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Obtener la zona horaria del navegador
    const expiry = now.add(ttl, 'millisecond').tz(timezone).format(); // Calcular fecha de expiración

    const item = {
        value: value,
        expiry: expiry,
    };

    localStorage.setItem(key, JSON.stringify(item));
}

// Función para obtener un ítem con verificación de expiración
function getItemWithExpiration(key) {
    const itemStr = localStorage.getItem(key);

    // Si el ítem no existe, retornar null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = dayjs().tz(Intl.DateTimeFormat().resolvedOptions().timeZone); // Fecha actual con zona horaria
    const expiry = dayjs(item.expiry); // Convertir fecha de expiración

    // Si el ítem ha expirado, eliminarlo de `localStorage` y retornar null
    if (now.isAfter(expiry)) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

const state = {
    token: getItemWithExpiration('token') || '',
    user: null,
    isAuthenticated: !!getItemWithExpiration('token'),
};

const mutations = {
    setToken(state, { token, user }) {
        const ttl = 60 * 60 * 1000; // 1 hora en milisegundos

        state.token = token;
        state.isAuthenticated = true;
        state.user = user;

        setItemWithExpiration('token', token, ttl);
        // Opcional: manejar cookies si es necesario
        // Cookies.set('token', token, { expires: 1, secure: false, httpOnly: false, sameSite: 'Lax' });
    },
    clearToken(state) {
        state.token = '';
        state.isAuthenticated = false;
        state.user = null;

        localStorage.removeItem('token');
        // Opcional: eliminar cookies
        // Cookies.remove('token');
    },
};

const actions = {
    login({ commit }, { token, user }) {
        commit('setToken', { token, user });
    },
    logout({ commit }) {
        commit('clearToken');
    },
};

const getters = {
    isAuthenticated: (state) => {
        const token = getItemWithExpiration('token');
        return !!token && state.isAuthenticated;
    },
    token: (state) => state.token,
    user: (state) => state.user,
    tokenExpiry: () => getItemExpiry('token'), // Obtener la fecha de expiración del token
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};