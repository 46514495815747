import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Auth/Login.vue";
import Callback from "../views/Auth/Callback.vue";
import Namespace from "../views/Namespace/Namespace.vue";
import NamespaceAdd from "../views/Namespace/NamespaceAdd.vue";
import User from "../views/User/User.vue";
import UserUpdate from "../views/User/UserUpdate.vue";
import Deployment from "../views/Deployment/Deployment.vue";
import Stack from "../views/Stack/Stack.vue";
import StackTemplates from "../views/StackTemplates/StackTemplates.vue";
import StackAdd from "../views/Stack/StackAdd.vue";
import ScheduleSnapshot from "../views/Schedule/ScheduleSnapshot.vue";
import ScheduleScale from "../views/Schedule/ScheduleScale.vue";
import ScheduleSnapshotAdd from "../views/Schedule/ScheduleSnapshotAdd.vue";
import ScheduleScaleAdd from "../views/Schedule/ScheduleScaleAdd.vue";
import Snapshot from "../views/Snapshot/Snapshot.vue";
import StackRequest from "../views/Request/StackRequest.vue";
import StackRequestAdd from "../views/Request/StackRequestAdd.vue";
import UserRequest from "../views/User/Requests.vue";
import UserEvent from "../views/Event/UserEvent.vue";
import SystemEvent from "../views/Event/SystemEvent.vue";

import Error403 from "../views/Error/403.vue";



import store from '../store';
import dayjs from 'dayjs';

const routes = [
  {
    path: "/callback",
    name: "Callback",
    component: Callback,
    meta: {
      requiresAuth: false,
      hideLayout: true
    }
     
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
      hideLayout: true
    }
  },
  {
    path: "/",
    name: "Home",
    //redirect: "/",
    component: Home,
    meta: { requiresAuth: true, hideLayout: false }
  },
  {
    path: "/namespace",
    name: "Namespace",
    component: Namespace,
    meta: { requiresAuth: true, hideLayout: false }
  },
  {
    path: "/namespace/add",
    name: "NamespaceAdd",
    component: NamespaceAdd,
    meta: { requiresAuth: true, hideLayout: false }
  },
  {
    path: "/deployment/:namespace",
    name: "Deployment",
    component: Deployment,
    meta: { requiresAuth: true, hideLayout: false },
    props: true
  },
  {
    path: "/user",
    name: "User",
    component: User,
    meta: { requiresAuth: true, hideLayout: false },

  },
  {
    path: "/user/:id",
    name: "UserUpdate",
    component: UserUpdate,
    meta: { requiresAuth: true, hideLayout: false },
    props: true
  },
      {
    path: "/user/request",
    name: "UserRequest",
    component: UserRequest,
    meta: { requiresAuth: true, hideLayout: false },
    props: true
  }, 
  {
    path: "/stack",
    name: "Stack",
    component: Stack,
    meta: { requiresAuth: true, hideLayout: false },

  },  
  {
    path: "/stack/add",
    name: "StackAdd",
    component: StackAdd,
    meta: { requiresAuth: true, hideLayout: false },
    props: true
  }, 

  {
    path: "/stack/:id",
    name: "StackUpdate",
    component: StackAdd,
    meta: { requiresAuth: true, hideLayout: false },
    props: true
  },
  {
    path: "/templates",
    name: "StackTemplates",
    component: StackTemplates,
    meta: { requiresAuth: true, hideLayout: false },
    props: true
  },
  {
    path: "/schedule/snapshot",
    name: "ScheduleSnapshot",
    component: ScheduleSnapshot,
    meta: { requiresAuth: true, hideLayout: false },
    props: true
  }, 
  {
    path: "/schedule/snapshot/add",
    name: "ScheduleSnapshotAdd",
    component: ScheduleSnapshotAdd,
    meta: { requiresAuth: true, hideLayout: false },
    props: true
  }, 
  {
    path: "/schedule/scale",
    name: "ScheduleScale",
    component: ScheduleScale,
    meta: { requiresAuth: true, hideLayout: false },
    props: true
  }, 
  {
    path: "/schedule/scale/add",
    name: "ScheduleScaleAdd",
    component: ScheduleScaleAdd,
    meta: { requiresAuth: true, hideLayout: false },
    props: true
  }, 
  {
    path: "/event/user",
    name: "UserEvent",
    component: UserEvent,
    meta: { requiresAuth: true, hideLayout: false },
    props: true
  }, 

  {
    path: "/event/system",
    name: "SystemEvent",
    component: SystemEvent,
    meta: { requiresAuth: true, hideLayout: false },
    props: true
  },  
  // {
  //   path: "/schedule/add",
  //   name: "ScheduleAdd",
  //   component: ScheduleAdd,
  //   meta: { requiresAuth: true, hideLayout: false },
  //   props: true
  // }, 
  // {
  //   path: "/schedule/:id",
  //   name: "ScheduleUpdate",
  //   component: ScheduleUpdate,
  //   meta: { requiresAuth: true, hideLayout: false },
  //   props: true
  // }, 
  
  {
    path: "/snapshot",
    name: "Snapshot",
    component: Snapshot,
    meta: { requiresAuth: true, hideLayout: false },
    props: true
  }, 
  {
    path: "/request/stack",
    name: "StackRequest",
    component: StackRequest,
    meta: { requiresAuth: true, hideLayout: false },
    props: true
  }, 
    {
    path: "/request/stack/add",
    name: "StackRequestAdd",
    component: StackRequestAdd,
    meta: { requiresAuth: true, hideLayout: false },
    props: true
  }, 

  // {
  //   path: "/dashboard-default",
  //   name: "Dashboard",
  //   component: Dashboard,
  //   meta: { requiresAuth: true, hideLayout: false }
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   component: Tables,
  //   meta: { requiresAuth: true, hideLayout: false }
  // },
  // {
  //   path: "/billing",
  //   name: "Billing",
  //   component: Billing,
  //   meta: { requiresAuth: true, hideLayout: false }
  // },
  // {
  //   path: "/virtual-reality",
  //   name: "Virtual Reality",
  //   component: VirtualReality,
  //   meta: { requiresAuth: true, hideLayout: false }
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL",
  //   component: RTL,
  //   meta: { requiresAuth: true, hideLayout: false }
  // },
  // {
  //   path: "/profile",
  //   name: "Profile",
  //   component: Profile,
  //   meta: { requiresAuth: true, hideLayout: false }
  // },
  // {
  //   path: "/signin",
  //   name: "Signin",
  //   component: Signin,
  //   meta: { requiresAuth: true, hideLayout: false }
  // },
  // {
  //   path: "/signup",
  //   name: "Signup",
  //   component: Signup,
  //   meta: { requiresAuth: true, hideLayout: false }
  // },
  {
    path: "/error/403",
    name: "Error403",
    component: Error403,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isAuthenticated = store.getters['auth/isAuthenticated'];
    const user = store.getters['auth/user'];

    if (!isAuthenticated || !user) {
      next('/login');
    } else {
      const tokenExpiry = store.getters['auth/tokenExpiry'];

      if (tokenExpiry) {
        const expiryDate = dayjs(tokenExpiry).tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
        const now = dayjs().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);

        if (now > expiryDate) {
          store.dispatch('auth/logout'); // Limpia el estado y localStorage
          next('/login');
        } else {
          if (user.active === false) {
            window.location.href = "/403.html";
          } else {
            next();
          }
        }
      } else {
        next('/login');
      }
    }
  } else {
    next();
  }
});
export default router;
