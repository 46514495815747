<template>
  <div class="py-7 container-fluid">
    <div class="card">
      <div class="card-header pb-6">
        <div class="mb-3">
          <h6 v-if="stackrequests.length === 0">Users requests not found</h6>
          <h6 v-else>Requests</h6>
        </div>
      </div>
      <div v-if="stackrequests.length !== 0" class="card-body px-5 pt-5 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center justify-content-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  #
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  User
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Project
                </th>
               <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Template
                </th>                  
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Stack
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Timezone
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Creation date
                </th>              
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Additional info
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Status
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="request in stackrequests" :key="request.id">
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ request.ID }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ request.user.displayName }}
                  </p>
                </td>
                <td>
                  <div v-if="request.namespace">
                    <p class="text-xs font-weight-bold mb-0">
                      {{ request.namespace.name }}
                    </p>
                  </div>
                  <div v-else>
                    <p class="text-xs font-weight-bold mb-0">
                      {{ request.newNamespace }}
                    </p>
                  </div>
                </td>
                <td>
                    <p class="text-xs font-weight-bold mb-0" v-if="request.template">{{ request.template.version }} {{ request.template.name }}</p>
                    <p v-else>-</p>
                </td>                  
                <td>
                    <p class="text-xs font-weight-bold mb-0" v-if="request.imageStack">{{ request.imageStack.name }}</p>
                    <p v-else>-</p>                  

                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ request.timezone }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ request.date }}
                  </p>
                </td>              
                <td>
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    :data-bs-target="'#pass-' + request.ID"
                    style="
                      padding: 0;
                      border: none;
                      background: none;
                      color: var(--bs-primary);
                      text-decoration: none;
                    "
                  >
                    <i class="bi bi-eye-fill font-weight-bold">Info</i>
                  </a>
                  <div
                    class="modal fade"
                    :id="'pass-' + request.ID"
                    tabindex="-1"
                    aria-labelledby="ModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="additionalInfo">
                            Additional Information
                          </h5>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>

                        <div class="modal-body">
                          <div
                            v-if="request.additionalInfo"
                            class="h8 mb-0 font-weight text-gray-800"
                          >
                            {{ request.additionalInfo }}
                          </div>
                          <div v-else class="h8 mb-0 font-weight text-gray-800">
                            -
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div v-if="request.status.name === 'Pending'">
                    <p>
                      <span class="badge bg-warning text-dark">
                        {{ request.status.name }}</span
                      >
                    </p>
                  </div>
                  <div v-if="request.status.name === 'Completed'">
                    <p>
                      <span class="badge bg-completed text-dark">
                        {{ request.status.name }}</span
                      >
                    </p>
                  </div>
                  <div
                    v-if="
                      request.status.name === 'Cancel by user' ||
                      request.status.name === 'Cancel by admin'
                    "
                  >
                    <p>
                      <span class="badge bg-danger text-dark">
                        {{ request.status.name }}</span
                      >
                    </p>
                  </div>
                </td>
                <td>
                  <button
                    style="
                      padding: 5;
                      border: none;
                      background: none;
                      color: var(--bs-primary);
                      text-decoration: font-weight-bold;
                    "
                    @click="cancelRequest(request)"
                  >
                    <i class="bi bi-x-lg font-weight-bold">Cancel</i>
                  </button>
                  <button
                    style="
                      padding: 5;
                      border: none;
                      background: none;
                      color: var(--bs-primary);
                      text-decoration: font-weight-bold;
                    "
                    @click="completeRequest(request)"
                  >
                    <i class="bi bi-check-lg font-weight-bold">Complete</i>
                  </button>
                  <button
                    style="
                      padding: 0;
                      border: none;
                      background: none;
                      color: var(--bs-primary);
                      text-decoration: none;
                    "
                    @click="deleteRequest(request)"
                  >
                    <i class="bi bi-x-lg font-weight-bold">Remove</i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="changePage(currentPage - 1)">
                  <i class="bi bi-arrow-left"></i>
                </button>
              </li>
              <li
                class="page-item"
                v-for="page in totalPages"
                :key="page"
                :class="{ active: page === currentPage }"
              >
                <button class="page-link" @click="changePage(page)">
                  {{ page }}
                </button>
              </li>
              <li
                class="page-item"
                :class="{ disabled: currentPage === totalPages }"
              >
                <button class="page-link" @click="changePage(currentPage + 1)">
                  <i class="bi bi-arrow-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

const toast = useToast();
export default {
  components: {},
  data() {
    return {
      stackrequests: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  mounted() {
    this.loadRequests();
  },
  methods: {
    async loadRequests() {
      try {
        const response = await this.$api.get(
          `/v1/user/request?page=${this.currentPage}&pagesize=${this.pageSize}`
        );
        if (response.status >= 200 && response.status < 300) {
          this.stackrequests = response.data.stackrequests;
          this.total = response.data.total;
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Redirigir a la página de Forbidden
          this.$router.push("/error/403");
        } else {
          console.error("Error fetching users:", error);
        }
      }
    },
    async completeRequest(request) {
      try {
        const response = await this.$api.put(
          `/v1/user/request?complete=true`,
          request
        );
        if (response.status >= 200 && response.status < 300) {
          toast.success("Request update successful");
          this.loadRequests();
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Redirigir a la página de Forbidden
          this.$router.push("/error/403");
        } else {
          console.error("Error fetching users:", error);
        }
      }
    },
    async cancelRequest(request) {
      try {
        const response = await this.$api.put(
          `/v1/request/cancel?by=admin`,
          request
        );
        if (response.status >= 200 && response.status < 300) {
          toast.success("Request update successful");
          this.loadRequests();
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Redirigir a la página de Forbidden
          this.$router.push("/error/403");
        } else {
          console.error("Error fetching users:", error);
        }
      }
    },
    async deleteRequest(request) {
      try {
        const response = await this.$api.delete(
          `/v1/user/request/${request.ID}`
        );
        if (response.status >= 200 && response.status < 300) {
          toast.success("Request delete successful");
          this.loadRequests();
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Redirigir a la página de Forbidden
          this.$router.push("/error/403");
        } else {
          console.error("Request delete: ", error);
        }
      }
    },
    changePage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.currentPage = page;
      this.loadRequests();
    },
  },
};
</script>
