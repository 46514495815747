<script setup></script>
<template>
  <div class="py-7 container-fluid">
    <div class="card">
      <div class="card-header pb-0">
        <div v-if="loading">Loading...</div>
        <div v-else>
          <h6 v-if="namespaces.length === 0">Projects not found</h6>
          <h6 v-else>Projects</h6>
          <div class="mb-3" v-if="user && user.admin">
            <router-link
              class="btn btn-primary btn-sm"
              :to="{
                name: 'NamespaceAdd',
              }"
              >Add</router-link
            >
          </div>
          <div v-if="!showFavoritesChecked" class="input-group">
            <div class="form-outline" data-mdb-input-init>
              <input
                type="search"
                id="form1"
                class="form-control"
                placeholder="Search project"
                v-model="searchNamespaceSelected"
                @input="searchNamespace(searchNamespaceSelected)"
              />
              <label class="form-label" for="form1"> </label>
            </div>
          </div>          
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              v-model="showFavoritesChecked"
              @change="showFavorites"
            />
            <label class="form-check-label" for="flexSwitchCheckDefault">
              Favorites
            </label>
          </div>          
        </div>
      </div>
      <div class="row">
        <div class="table-responsive p-0">
          <table class="table align-items-center justify-content-center mb-0">
            <div class="col-lg-10">
              <div class="row mt-4">
                <div class="row">
                  <div
                    class="col-lg-2 col-md-6 col-12"
                    v-for="namespace in filteredNamespaces"
                    :key="namespace"
                  >
                    <div class="card m-3 w-100">
                      <div class="card-body position-relative">
                        <button
                          v-if="!showFavoritesChecked"
                          class="btn btn-link position-absolute top-0 end-0 m-2 p-0"
                          title="Snapshot"
                          @click="addFavorite(namespace)"
                        >
                          <i class="bi bi-heart-fill text-primary"></i>
                        </button>
                        <button
                          v-else
                          class="btn btn-link position-absolute top-0 end-0 m-2 p-0"
                          title="Snapshot"
                          @click="deleteFavorite(namespace)"
                        >
                          <i class="bi bi-trash-fill text-primary"></i>
                        </button>                        
                        <h5 class="card-title">
                          <i class="bi bi-stack"></i>
                          {{ namespace.name }}
                        </h5>
                        <div class="mb-3">
                          <!-- Enlace para editar -->
                          <router-link
                            class="btn btn-primary btn-xs"
                            :to="{
                              name: 'Deployment',
                              params: { namespace: namespace.name },
                            }"
                            >Stacks</router-link
                          >
                          <!-- Botón para snapshot -->
                          <div class="mb-3">
                            <button
                              v-if="user && user.admin"
                              style="
                                border: none;
                                background: none;
                                color: var(--bs-primary);
                                text-decoration: none;
                              "
                              title="Snapshot"
                              @click="disableNamespace(namespace)"
                            >
                              <i class="bi bi-eye-slash-fill"></i> Disable
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </table>
          <nav v-if="!showFavoritesChecked" aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="changePage(currentPage - 1)">
                  <i class="bi bi-arrow-left"></i>
                </button>
              </li>
              <li
                class="page-item"
                v-for="page in totalPages"
                :key="page"
                :class="{ active: page === currentPage }"
              >
                <button class="page-link" @click="changePage(page)">
                  {{ page }}
                </button>
              </li>
              <li
                class="page-item"
                :class="{ disabled: currentPage === totalPages }"
              >
                <button class="page-link" @click="changePage(currentPage + 1)">
                  <i class="bi bi-arrow-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

const toast = useToast();
import { mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {
      showFavoritesChecked: false,
      searchNamespaceSelected: "",
      filteredNamespaces: [],
      namespaces: [],
      favorites: [],
      loading: true,
      searching: false,
      currentPage: 1,
      pageSize: 9,
      total: 0,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    showUsername() {
      return this.$store.getters["auth/loginUsername"];
    },
    totalPages() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  mounted() {
    this.loadNamespaces();
  },
  methods: {
    async showFavorites() {
      this.loadNamespaces();
      if (this.showFavoritesChecked) {
        this.filteredNamespaces = this.favorites.map(
          (favorite) => favorite.namespace
        );
      } else {
        this.filteredNamespaces = this.namespaces;
      }
    },
    async addFavorite(namespace) {
      try {
        const response = await this.$api.put("/v1/favorite/add", namespace);
        if (response.status >= 200 && response.status < 300) {
         // const message = this.$capitalize(this.$t("add_favorite"));
          toast.success("Add favorite successful");
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Redirigir a la página de Forbidden
          this.$router.push("/error/403");
        } else {
          //const message = this.$capitalize(this.$t("add_favorite_error"));
          toast.error("Error add favorite");
        }
      }
    },
    async deleteFavorite(namespace) {
      try {
        const response = await this.$api.delete(
          `/v1/favorite/delete?namespaceID=${namespace.ID}`
        );
        if (response.status >= 200 && response.status < 300) {
         // const message = this.$capitalize(this.$t("delete_favorite"));
          toast.success("Delete favorite successful");
          this.loadNamespaces();
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.$router.push("/error/403");
        } else {
          //const message = this.$capitalize(this.$t("delete_favorite_error"));
          toast.error("Delete favorite error");
        }
      }
    },            
    async loadNamespaces() {
      this.loading = true;
      this.searching = true;
      try {
        const params = {
          page: this.currentPage,
          pagesize: this.pageSize,
        };
        const response = await this.$api.get("/v1/namespace/user", { params });
        this.namespaces = response.data.namespaces;
        this.filteredNamespaces = response.data.namespaces;
        this.favorites = response.data.favorites;
        if (this.showFavoritesChecked) {
          this.filteredNamespaces = this.favorites.map(
            (favorite) => favorite.namespace
          );
        }
        this.total = response.data.total;
      } catch (error) {
        console.error("Error fetching namespaces:", error);
      } finally {
        this.loading = false;
        this.searching = false;
      }
    },
    searchNamespace(searchNamespace) {
      if (searchNamespace == "") {
        this.searching = false;
      }

      this.loading = false;
      this.$api
        .get(
          `/v1/namespace/user?page=${this.currentPage}&pagesize=${this.pageSize}&searchNamespace=${searchNamespace}`
        )
        .then((response) => {
          this.namespaces = response.data.namespaces;
          this.filteredNamespaces = response.data.namespaces;
          this.favorites = response.data.favorites;
          if (this.showFavoritesChecked) {
            this.filteredNamespaces = this.favorites.map(
              (favorite) => favorite.namespace
            );
          }
          this.total = response.data.total;
          this.searching = true;
        })
        .catch((error) => {
          console.error("Error fetching ns:", error);
        });
    },    
    changePage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.currentPage = page;
      this.loadNamespaces();
    },
    async disableNamespace(namespace) {
      try {
        const response = await this.$api.post(
          "/v1/namespace/disable",
          namespace
        );
        if (response.status >= 200 && response.status < 300) {
          toast.success("Namespace disable successful");
          this.loadNamespaces();
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Redirigir a la página de Forbidden
          this.$router.push("/error/403");
        } else {
          toast.error(error.response.data);
        }
      }
    },
  },
};
</script>
