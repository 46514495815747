<template>
  <div class="card w-50">
    <div class="card-header pb-0">
      <h6>New Stack request</h6>
    </div>
    <div class="card-body px-5 pt-5 pb-2">
      <div v-if="!request.newProject" class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Project</label>
        <select
          required
          class="form-select form-select-sm"
          aria-label="Default select example"
          v-model="request.namespace"
          :disabled="edit === true"
        >
          <option disabled>Select project</option>
          <option v-for="(ns, index) in namespaces" :key="index" :value="ns">
            {{ ns.name }}
          </option>
        </select>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="request.newProject"
          id="flexCheckactive"
        />
        <label class="form-check-label" for="flexCheckactive">
          New Project
        </label>
      </div>

      <div class="mb-3" v-if="request.newProject">
        <label for="exampleFormControlInput1" class="form-label"
          >Project name</label
        >
        <input
          required
          type="text"
          class="form-control form-control-sm"
          id="name"
          v-model="request.newNamespace"
          :style="{ width: inputWidth }"
        />
      </div>
      <div class="form-check" v-if="request.namespace && !request.newProject">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="request.fromTemplate"
          @focus="loadTemplates"
          id="fromTemplate"
        />
        <label class="form-check-label" for="flexCheckactive">
          From template
        </label>
      </div>  
      <div class="mb-3" v-if="request.fromTemplate && request.namespace && !request.newProject">
        <label for="exampleFormControlInput1" class="form-label"
          >Templates</label
        >
        <select
          class="form-select form-select-sm"
          aria-label="Default select example"
          v-model="request.template"
        >
          <option disabled>Select template</option>
          <option v-for="(t, index) in templates" :key="index" :value="t">
           {{ t.version }} {{ t.name }}
          </option>
        </select>
      </div>          

      <div class="mb-3" v-if="!request.template">
        <label for="exampleFormControlInput1" class="form-label"
          >Image stack</label
        >
        <select
          required
          class="form-select form-select-sm"
          aria-label="Default select example"
          v-model="request.imageStack"
        >
          <option disabled>Select image stack</option>
          <option
            v-for="(tag, index) in imagesStacks"
            :key="index"
            :value="tag"
          >
            {{ tag.name }}
          </option>
        </select>
      </div>

      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Timezone</label
        >
        <select
          class="form-select form-select-sm"
          aria-label="Default select example"
          v-model="request.timezone"
        >
          <option disabled selected>Select timezone</option>
          <option
            v-for="(tz, index) in timezones.name"
            :key="index"
            :value="tz"
          >
            {{ tz }}
          </option>
        </select>
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="additionalinfocheck"
          id="additionalinfocheck"
        />
        <label class="form-check-label" for="additionalinfocheck">
          Additional information
        </label>
      </div>
      <div v-if="additionalinfocheck" class="mb-3">
        <label for="exampleFormControlTextarea1" class="form-label"
          >Add information</label
        >
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          v-model="request.additionalInfo"
        ></textarea>
      </div>
      <div class="mb-3">
        <button
          @click="addStackRequest()"
          type="button"
          class="btn btn-primary"
        >
          <span>Add</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { mapGetters, mapActions } from "vuex";

const toast = useToast();
export default {
  components: {},
  created() {},
  data() {
    return {
      request: {
        namespace: null,
        newProject: false,
        newProjectName: "",
        imageStack: null,
        user: null,
        timezone: null,
        additionalInfo: null,
        fromTemplate: false,
        template: null,
      },
      additionalinfocheck: false,
      namespaces: [],
      templates: [],
      imagesStacks: [],
      timezones: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  mounted() {
    this.loadNamespaces();
    this.loadImageStack();
    this.loadTimezones();
  },
  methods: {
    ...mapActions("auth", ["updateUser"]),
    loadTemplates() {
      this.$api
        .get(`/v1/request/templates?namespace=${this.request.namespace.name}`)
        .then((response) => {
          this.templates = response.data.stackTemplates;
        })
        .catch((error) => {
          console.error("Error fetching ns:", error);
        });
    },
    loadNamespaces() {
      this.$api
        .get("/v1/namespace")
        .then((response) => {
          this.namespaces = response.data;
        })
        .catch((error) => {
          console.error("Error fetching ns:", error);
        });
    },
    loadImageStack() {
      this.$api
        .get("/v1/image")
        .then((response) => {
          this.imagesStacks = response.data;
        })
        .catch((error) => {
          console.error("Error fetching images:", error);
        });
    },
    loadTimezones() {
      this.$api
        .get("/v1/utils/timezone")
        .then((response) => {
          this.timezones = response.data;
        })
        .catch((error) => {
          console.error("Error fetching resources:", error);
        });
    },
    async addStackRequest() {
      try {
        if(this.request.template){
          this.request.imageStack = null;
        }
        this.request.user = this.user;
        const response = await this.$api.post(
          "/v1/request/stack",
          this.request
        );
        if (response.status >= 200 && response.status < 300) {
          toast.success("Stack request create successful");

          this.$router.push({ name: "StackRequest" });
        }
      } catch (error) {
        toast.error(error.response.data);
      }
    },
  },
};
</script>
