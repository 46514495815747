<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import SidenavItem from "./SidenavItem.vue";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};
</script>
<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/"
          :class="getRoute() === 'Home' ? 'active' : ''"
          :navText="isRTL ? 'لوحة القيادة' : 'Home'"
        >
          <template v-slot:icon>
            <i
              class="ni bi-house-door-fill text-primary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          USER TOOLS
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/namespace"
          :class="getRoute() === 'Namespace' ? 'active' : ''"
          :navText="isRTL ? 'لوحة القيادة' : 'Project'"
        >
          <template v-slot:icon>
            <i
              class="ni bi-collection-fill text-primary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="/request/stack"
          :class="getRoute() === 'RequestStack' ? 'active' : ''"
          navText="Stack request"
        >
          <template v-slot:icon>
            <i
              class="ni bi-collection-fill text-primary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <div v-if="user && user.admin">
        <li class="mt-3 nav-item">
          <h6
            class="text-xs ps-4 font-weight-bolder opacity-6"
            :class="isRTL ? 'me-4' : 'ms-2'"
          >
            ADMIN TOOLS
          </h6>
        </li>
        <li class="mt-3 nav-item">
          <h9
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
            :class="isRTL ? 'me-4' : 'ms-2'"
          >
            Users
          </h9>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/user"
            :class="getRoute() === 'user' ? 'active' : ''"
            :navText="isRTL ? 'حساب تعريفي' : 'User'"
          >
            <template v-slot:icon>
              <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/user/request"
            :class="getRoute() === 'UserRequest' ? 'active' : ''"
            navText="Requests"
          >
            <template v-slot:icon>
              <i
                class="bi bi-person-fill-exclamation text-primary text-sm opacity-10"
              ></i>
            </template>
          </sidenav-item>
        </li>
        <li class="mt-3 nav-item">
          <h9
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
            :class="isRTL ? 'me-4' : 'ms-2'"
          >
            Stacks
          </h9>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/stack"
            :class="getRoute() === 'stack' ? 'active' : ''"
            navText="Stack"
          >
            <template v-slot:icon>
              <i class="ni bi-stack text-primary text-sm opacity-10"></i>
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/templates"
            :class="getRoute() === 'stacktemplates' ? 'active' : ''"
            navText="Templates"
          >
            <template v-slot:icon>
              <i class="ni bi-stack text-primary text-sm opacity-10"></i>
            </template>
          </sidenav-item>
        </li>
        <li class="mt-3 nav-item">
          <h9
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
            :class="isRTL ? 'me-4' : 'ms-2'"
          >
            Schedules
          </h9>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/schedule/scale"
            :navText="isRTL ? 'حساب تعريفي' : 'Scale'"
          >
            <template v-slot:icon>
              <i
                class="ni bi-stopwatch-fill text-primary text-sm opacity-10"
              ></i>
            </template>
          </sidenav-item>
        </li>
        <!-- oculto -->
        <li class="nav-item" v-if="shouldShowItem">
          <sidenav-item
            to="/schedule/snapshot"
            :navText="isRTL ? 'حساب تعريفي' : 'Snapshots'"
          >
            <template v-slot:icon>
              <i
                class="ni bi-stopwatch-fill text-primary text-sm opacity-10"
              ></i>
            </template>
          </sidenav-item>
        </li>
        <li class="mt-3 nav-item">
          <h9
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
            :class="isRTL ? 'me-4' : 'ms-2'"
          >
            Snapshots
          </h9>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/snapshot"
            :class="getRoute() === 'snapshot' ? 'active' : ''"
            :navText="isRTL ? 'حساب تعريفي' : 'Snapshot'"
          >
            <template v-slot:icon>
              <i class="ni bi-archive-fill text-primary text-sm opacity-10"></i>
            </template>
          </sidenav-item>
        </li>
        <li class="mt-3 nav-item">
          <h9
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
            :class="isRTL ? 'me-4' : 'ms-2'"
          >
            Events
          </h9>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/event/system"
            :class="getRoute() === 'SystemEvent' ? 'active' : ''"
            navText="System events"
          >
            <template v-slot:icon>
              <i class="ni bi-archive-fill text-primary text-sm opacity-10"></i>
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item
            to="/event/user"
            :class="getRoute() === 'UserEvent' ? 'active' : ''"
            navText="User events"
          >
            <template v-slot:icon>
              <i class="ni bi-archive-fill text-primary text-sm opacity-10"></i>
            </template>
          </sidenav-item>
        </li>
        <!-- <li class="mt-3 nav-item">
          <h9
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
            :class="isRTL ? 'me-4' : 'ms-2'"
          >
            Configuration
          </h9>
        </li>
        <li class="nav-item">
          <sidenav-item
            :to="$route.path"
            :class="getRoute() === 'UserEvent' ? 'active' : ''"
            navText="Reload global config"
            @click="reloadConfig"
          >
            <template v-slot:icon>
              <i class="ni bi-archive-fill text-primary text-sm opacity-10"></i>
            </template>
          </sidenav-item>
        </li> -->
      </div>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  mounted() {},
  methods: {
    redirectToOpenvpn() {
      // Obtén la URL desde la variable de entorno
      const openvpnUrl = process.env.VUE_APP_OPENVPN_URL;
      // Redirige a la URL
      window.location.href = openvpnUrl;
    },
    async reloadConfig() {
      try {
        const response = await this.$api.get(`/v1/config/reload`);
        if (response.status >= 200 && response.status < 300) {
          toast.success("Reload config successful");
        }
      } catch (error) {
        toast.error("Reload config error");
      }
    },
  },
};
</script>
