<template>
  <div class="py-7 container-fluid">
    <div class="card w-70">
      <div class="card-header pb-0">
        <h6>Users</h6>
      </div>
      <div class="card-body px-5 pt-5 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center justify-content-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  #
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Name
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Mail
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Admin
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Active
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ user.ID }}</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ user.displayName }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ user.mail }}</p>
                </td>
                <td>
                  <span v-if="user.admin === true"
                    ><i class="bi bi-check success"></i
                  ></span>
                  <span v-else><i class="bi bi-x"></i></span>
                </td>
                <td>
                  <span v-if="user.active === true"
                    ><i class="bi bi-check success"></i
                  ></span>
                  <span v-else><i class="bi bi-x"></i></span>
                </td>
                <td>
                  <router-link
                    style="
                      padding: 0;
                      border: none;
                      background: none;
                      color: var(--bs-primary);
                      text-decoration: none;
                    "
                    :to="{
                      name: 'UserUpdate',
                      params: { id: user.ID },
                    }"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z"
                      /></svg
                  ></router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="changePage(currentPage - 1)">
                  <i class="bi bi-arrow-left"></i>
                </button>
              </li>
              <li
                class="page-item"
                v-for="page in totalPages"
                :key="page"
                :class="{ active: page === currentPage }"
              >
                <button class="page-link" @click="changePage(page)">
                  {{ page }}
                </button>
              </li>
              <li
                class="page-item"
                :class="{ disabled: currentPage === totalPages }"
              >
                <button class="page-link" @click="changePage(currentPage + 1)">
                  <i class="bi bi-arrow-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      users: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
  async loadUsers() {
    try {
      const response = await this.$api.get(`/v1/user?page=${this.currentPage}&pagesize=${this.pageSize}`);
      this.users = response.data.users;
      this.total = response.data.total;
    } catch (error) {
      this.handleError(error, "Error fetching users");
    }
  },
  
  async updateUser(user) {
    try {
      // Se usa POST o PUT dependiendo de la naturaleza de la actualización
      const response = await this.$api.post(`/v1/user/update`, user);
      this.users = response.data;
    } catch (error) {
      this.handleError(error, "Error updating user");
    }
  },

  changePage(page) {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
    this.loadUsers();
  },

  handleError(error, message) {
    if (error.response && error.response.status === 403) {
      // Redirigir a la página de Forbidden
      this.$router.push("/error/403");
    } else {
      console.error(`${message}:`, error);
    }
  },
  },
};
</script>
