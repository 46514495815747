import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import ArgonDashboard from "./argon-dashboard";
import axios from 'axios';
import './assets/css/custom.css';

const loadConfig = async () => {
  try {
    const response = await fetch('/config.json');
    const runtimeConfig = await response.json();

    return {
      ...process.env, // Variables del build
      ...runtimeConfig // Variables runtime
    };
  } catch (error) {
    console.error("Error loading runtime configuration:", error);
    return process.env; // Si falla, usar solo las variables del entorno
  }
};

const configureAxios = (config) => {
  axios.defaults.baseURL = config.VUE_APP_BASE_URL || 'http://localhost:3000';
  axios.defaults.withCredentials = true;

  axios.interceptors.request.use(
    requestConfig => {
      const token = store.state.auth.token;
      if (token) {
        requestConfig.headers.Authorization = `Bearer ${token}`;
      }
      return requestConfig;
    },
    error => Promise.reject(error)
  );

  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );
};

loadConfig().then((config) => {
  configureAxios(config);

  const appInstance = createApp(App);

  appInstance.use(Toast, {
    position: POSITION.TOP_RIGHT,
  });
  appInstance.use(store);
  appInstance.use(router);
  appInstance.use(ArgonDashboard);

  // Configuración global
  appInstance.config.globalProperties.$api = axios;
  appInstance.config.globalProperties.$version = config.VUE_APP_VERSION || 'v1.0.0'; // Fallback explícito
  appInstance.config.globalProperties.$grafana_url = config.VUE_APP_GRAFANA_URL || 'http://localhost'; // Fallback explícito
  appInstance.config.globalProperties.$environment = config.VUE_APP_ENVIRONMENT || 'Developer'; // Fallback explícito  

  appInstance.mount('#app');

  // Verificar que la versión está configurada
  
});
