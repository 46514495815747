<template>
  <div class="py-7 container-fluid">
    <div class="card">
      <div class="card-header pb-3">
        <div class="mb-3">
          <h6 v-if="userReturned.stackRequest.length === 0">
            Stacks Request not found
          </h6>
          <h6 v-else>Stack Requests</h6>
          <router-link
            class="btn btn-primary btn-sm"
            :to="{
              name: 'StackRequestAdd',
            }"
            >Add</router-link
          >
        </div>
      </div>
      <div
        v-if="userReturned.stackRequest.length !== 0"
        class="card-body px-5 pt-5 pb-2"
      >
        <div class="table-responsive p-0">
          <table class="table align-items-center justify-content-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  #
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Project
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Stack
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Timezone
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Creation date
                </th>    
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Template
                </th>                            
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Additional info
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Status
                </th>

                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="request in userReturned.stackRequest"
                :key="request.id"
              >
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ request.ID }}
                  </p>
                </td>
                <td>
                  <div v-if="request.namespace">
                    <p class="text-xs font-weight-bold mb-0">
                      {{ request.namespace.name }}
                    </p>
                  </div>
                  <div v-else>
                    <p class="text-xs font-weight-bold mb-0">
                      {{ request.newNamespace }}
                    </p>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0" v-if="request.imageStack">{{ request.imageStack.name }}</p>
                    <p v-else>-</p>                  
                </td>
                <td>
                    <p class="text-xs font-weight-bold mb-0" v-if="request.template">{{ request.template.version }} {{ request.template.name }}</p>
                    <p v-else>-</p>
          
                </td>                    
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ request.timezone }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ request.date }}
                  </p>
                </td>            
                <td>
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    :data-bs-target="'#pass-' + request.ID"
                    style="
                      padding: 0;
                      border: none;
                      background: none;
                      color: var(--bs-primary);
                      text-decoration: none;
                    "
                  >
                    <i class="bi bi-eye-fill font-weight-bold">Info</i>
                  </a>
                  <div
                    class="modal fade"
                    :id="'pass-' + request.ID"
                    tabindex="-1"
                    aria-labelledby="ModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="additionalInfo">
                            Additional Information
                          </h5>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>

                        <div class="modal-body">
                          <div
                            v-if="request.additionalInfo"
                            class="h8 mb-0 font-weight text-gray-800"
                          >
                            {{ request.additionalInfo }}
                          </div>
                          <div v-else class="h8 mb-0 font-weight text-gray-800">
                            -
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div v-if="request.status.name === 'Pending'">
                    <p>
                      <span class="badge bg-warning text-dark">
                        {{ request.status.name }}</span
                      >
                    </p>
                  </div>
                  <div v-if="request.status.name === 'Completed'">
                    <p>
                      <span class="badge bg-completed text-dark">
                        {{ request.status.name }}</span
                      >
                    </p>
                  </div>
                  <div
                    v-if="
                      request.status.name === 'Cancel by admin' ||
                      request.status.name === 'Cancel by user'
                    "
                  >
                    <p>
                      <span class="badge bg-danger text-dark">
                        {{ request.status.name }}</span
                      >
                    </p>
                  </div>
                </td>

                <td>
                  <div v-if="request.status.name == 'Pending'">
                    <button
                      style="
                        padding: 5;
                        border: none;
                        background: none;
                        color: var(--bs-primary);
                        text-decoration: font-weight-bold;
                      "
                      @click="CancelRequestByUser(request)"
                    >
                      <i class="bi bi-x-lg font-weight-bold">Cancel</i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="changePage(currentPage - 1)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-left-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
                    />
                  </svg>
                </button>
              </li>

              <li
                class="page-item"
                v-for="page in totalPages"
                :key="page"
                :class="{ active: page === currentPage }"
              >
                <button class="page-link" @click="changePage(page)">
                  {{ page }}
                </button>
              </li>

              <li
                class="page-item"
                :class="{ disabled: currentPage === totalPages }"
              >
                <button class="page-link" @click="changePage(currentPage + 1)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";

const toast = useToast();
export default {
  components: {},
  data() {
    return {
      userReturned: {
        stackRequest: [],
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    async loadUser() {
      try {
        const response = await this.$api.post(`/v1/user/get`, this.user);
        if (response.status >= 200 && response.status < 300) {
          this.userReturned = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async CancelRequestByUser(request) {
      try {
        const response = await this.$api.put(
          `/v1/request/cancel?by=user`,
          request
        );
        if (response.status >= 200 && response.status < 300) {
          this.loadUser();
          toast.success("Request canceled successful");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
