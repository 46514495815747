<template>
  <div class="py-7 container-fluid">
    <div class="card">
      <div class="card-header pb-0">
        <div v-if="loading">Loading...</div>
        <div v-else>
          <h6 v-if="deployments.length === 0">
            Stacks not found in {{ namespace }}
          </h6>
          <div v-else>
            <h6>Project: {{ namespace }}</h6>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row mt-4">
            <div class="row">
              <div
                class="col-lg-3 col-md-6 col-12"
                v-for="deployment in deployments"
                :key="deployment.Name"
              >
                <div class="card m-3">
                  <div class="card-body">
                    <h6 class="card-title">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-stack"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"
                        />
                        <path
                          d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"
                        />
                      </svg>
                      {{ deployment.name }}
                    </h6>
                    <div
                      v-if="deployment.replicas !== 0"
                      class="h8 mb-0 font-weight-bold text-gray-800"
                    >
                  <div class="status-container">
                    <p>
                      <i
                        class="fas fa-heartbeat status-icon"
                        style="color: #4caf50"
                      ></i>
                      <a
                        class="link-offset-2 link-underline link-underline-opacity-25"
                        :href="`${$grafana_url}&var-namespace=${namespace}&var-deployment=${deployment.name}`"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Haz clic para ver el estado de salud del proyecto en Grafana"
                      >
                        View metrics
                      </a>
                    </p>
                  </div>                    
                      <div class="h8 mb-0 font-weight-bold text-gray-800">
                        <div>
                          <argon-badge
                            v-if="deployment.status === 'Starting'"
                            variant="fill"
                            size="md"
                            color="warning"
                          >
                            {{ deployment.status }}
                          </argon-badge>
                          <argon-badge
                            v-if="deployment.status === 'Ready'"
                            variant="fill"
                            size="sm"
                            color="success"
                          >
                            {{ deployment.status }}
                          </argon-badge>
                        </div>
                        <div>
                          <argon-badge
                            v-if="deployment.status === 'Ready'"
                            variant="fill"
                            size="md"
                            color="success"
                          >
                            <i class="bi bi-alarm-fill"></i>
                            Active: {{ deployment.durationTime }}
                          </argon-badge>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="deployment.replicas === 0"
                      class="h8 mb-0 font-weight-bold text-gray-800"
                    >
                      <argon-badge variant="fill" size="md" color="warning">
                        Stopped
                      </argon-badge>
                    </div>
                    <argon-badge
                      v-for="annotation in deployment.annotations"
                      :key="annotation"
                      variant="fill"
                      size="sm"
                      color="secondary"
                    >
                      {{ annotation }}
                    </argon-badge>
                    <div v-for="pod in deployment.pod" :key="pod.name">
                      <argon-badge variant="fill" size="sm" color="secondary">
                        Cpu {{ pod.totalCpuLimit }}
                      </argon-badge>
                      <argon-badge variant="fill" size="sm" color="secondary">
                        Memory {{ pod.totalMemoryLimit }}
                      </argon-badge>
                    </div>
                    <div v-for="service in deployment.service" :key="service">
                      <argon-badge variant="fill" size="sm" color="secondary">
                        Ip {{ service.ip }}
                      </argon-badge>
                      <argon-badge variant="fill" size="sm" color="secondary">
                        Domain {{ deployment.name }}.{{
                          deployment.namespace
                        }}.svc.cluster.local
                      </argon-badge>
                    </div>
                    <p></p>
                    <button
                      v-if="deployment.replicas === 0"
                      type="submit"
                      class="btn btn-primary btn-xs"
                      @click="scale(deployment, 1)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-play-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393"
                        />
                      </svg>
                      Start
                    </button>
                    <button
                      v-if="
                        (deployment.replicas >= 1 &&
                          deployment.status === 'Ready') ||
                        deployment.status === 'Starting'
                      "
                      type="submit"
                      class="btn btn-primary btn-xs"
                      @click="scale(deployment, 0)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-stop-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5"
                        />
                      </svg>
                      Stop
                    </button>

                    <div
                      v-if="
                        deployment.replicas >= 1 &&
                        deployment.status === 'Ready'
                      "
                    ></div>
                    <div
                      v-if="
                        deployment.replicas >= 1 &&
                        deployment.status === 'Ready'
                      "
                    >
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        :data-bs-target="'#pass-' + deployment.stack.ID"
                        class="btn btn-primary btn-xs"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-key"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8m4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5"
                          />
                          <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                        </svg>
                        Credentials
                      </a>
                      <div
                        class="modal fade"
                        :id="'pass-' + deployment.stack.ID"
                        tabindex="-1"
                        aria-labelledby="ModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="conectar">
                                Credentials
                              </h5>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>

                            <div class="modal-body">
                              <!-- Usuario -->
                              <div class="h8 mb-2 font-weight-bold text-gray-800">
                                User: developer
                              </div>

                              <!-- Contraseña con botón de ocultar/mostrar y copiar -->
                              <div class="d-flex align-items-center">
                                <input
                                  :type="showPassword ? 'text' : 'password'"
                                  class="form-control form-control-sm me-2"
                                  v-model="deployment.remotePassword"
                                  readonly
                                />
                                <button @click="togglePasswordVisibility" class="btn btn-sm btn-outline-secondary me-2">
                                  <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                                </button>
                                <button @click="copyPassword(deployment.remotePassword)" class="btn btn-sm btn-outline-primary">
                                  <i class="fas fa-copy"></i>
                                </button>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-for="ingress in deployment.ingress"
                        :key="ingress.host"
                      >
                        <a
                          :href="'http://' + ingress.host"
                          target="_blank"
                          class="btn btn-primary btn-xs"
                        >
                          <div v-if="ingress.name.includes('-web')">
                           <i class="bi bi-pc-display-horizontal"></i>
                            <span> Connect</span>
                          </div>
                          <div v-if="ingress.name.includes('-ftp')">
                            <i class="bi bi-link"></i>
                            <span> FTP Access </span>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div>
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        :data-bs-target="'#config-' + deployment.stack.ID"
                        class="btn btn-primary btn-xs"
                      >
                        <i class="bi bi-gear-fill"></i>
                        Configure
                      </a>
                      <div
                        class="modal fade"
                        :id="'config-' + deployment.stack.ID"
                        tabindex="-1"
                        aria-labelledby="ModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="conectar">
                                Configure Stack {{ deployment.stack.ID }}
                              </h5>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>

                            <div class="modal-body">
                              <div
                                v-if="deployment.stack.scheduleScale"
                                class="h8 mb-0 font-weight-bold text-gray-800"
                              >
                                <h7> Autostop Schedule: </h7>
                                <div>
                                  Cron:
                                  <span class="badge bg-secondary">{{
                                    deployment.stack.scheduleScale.cron
                                  }}</span>
                                </div>
                                <div>
                                  Status:
                                  <span
                                    v-if="
                                      deployment.stack.scheduleScale.disable
                                    "
                                    class="badge bg-secondary"
                                  >
                                    disable for 4 hours
                                  </span>
                                  <div
                                    v-if="
                                      deployment.stack.scheduleScale.disable
                                    "
                                  >
                                    Disable by:
                                    <span class="badge bg-secondary">
                                      {{
                                        deployment.stack.scheduleScale.disableBy
                                      }}
                                    </span>
                                  </div>
                                  <span v-else class="badge bg-secondary">
                                    enabled
                                  </span>
                                </div>
                                <p></p>
                                <div
                                  v-if="!deployment.stack.scheduleScale.disable"
                                >
                                  <button
                                    type="submit"
                                    class="btn btn-primary btn-xs"
                                    @click="autostop(deployment, true)"
                                  >
                                    <i class="bi bi-stop-fill"></i>
                                    Disable Autostop for 4 hours
                                  </button>
                                </div>
                                <div v-else>
                                  <button
                                    type="submit"
                                    class="btn btn-primary btn-xs"
                                    @click="autostop(deployment, false)"
                                  >
                                    <i class="bi bi-stop-fill"></i>
                                    Enable Autostop
                                  </button>
                                </div>
                              </div>
                              <div v-else>
                                <h7>Autostop Scheduler not configured</h7>
                              </div>
                              <hr />

                              <div
                                v-if="deployment.stack"
                                class="h8 mb-0 font-weight-bold text-gray-800"
                              >
                                <h7> Stack info: </h7>
                                <div>
                                  Image stack:
                                  <span class="badge bg-secondary">{{
                                    deployment.stack.imageStack.name
                                  }}</span>
                                </div>
                                <div>
                                  New image stack:
                                  <div class="mb-3">
                                    <select
                                      required
                                      class="form-select form-select-sm"
                                      aria-label="Default select example"
                                      v-model="newImage"
                                      @focus="loadImageStack()"
                                    >
                                      <option disabled>
                                        Select image stack
                                      </option>
                                      <option
                                        v-for="(tag, index) in imagesStacks"
                                        :key="index"
                                        :value="tag"
                                      >
                                        {{ tag.name }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <p></p>
                                <div>
                                  <button
                                    type="submit"
                                    class="btn btn-primary btn-xs"
                                    @click="changeImageStack(deployment)"
                                  >
                                    <i class="bi bi-stack"></i>
                                    Change image stack
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";
import { createWebSocket } from "@/utils/websocket";

const toast = useToast();
export default {
  components: {
    ArgonBadge,
  },
  data() {
    return {
       showPassword: false,
      deployments: [],
      loading: true,
      disableAutostopCheck: false,
      imagesStacks: [],
      newImage: null,
      intervalId: null,
      socket: null,
      reconnecting: false, // To prevent multiple reconnections
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),

  },
  props: ["namespace"],
  mounted() {
    this.closeLoadDeployment();
    this.loadDeployments();
    if (this.enableRefresh) {
      this.startInterval();
    }
  },
  beforeUnmount() {
    this.stopInterval();
    this.closeLoadDeployment();
  },
  watch: {
    enableRefresh(newVal) {
      if (newVal) {
        // Activar intervalo si el checkbox está activado
        this.startInterval();
      } else {
        // Limpiar intervalo si el checkbox está desactivado
        this.stopInterval();
      }
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword; // Alternar entre mostrar y ocultar contraseña
    },
    copyPassword(remotePassword) {
      navigator.clipboard.writeText(remotePassword)
        .then(() => {
          toast.info("Password copied to clipboard!");
        })
        .catch(err => {
          console.error("Failed to copy password:", err);
        });
    },
    startInterval() {
      if (!this.intervalId) {
        this.loadDeployments();
        this.intervalId = setInterval(this.loadDeployments, 5000); // Ejecutar cada 5 segundos
      }
    },
    stopInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    async autostop(deployment, disable) {
      if (disable) {
        deployment.stack.scheduleScale.disable = true;
        deployment.stack.scheduleScale.disableBy = this.user.displayName;
      } else {
        deployment.stack.scheduleScale.disable = false;
      }
      try {
        const response = await this.$api.put(
          `/v1/deployment/autostop`,
          deployment
        );
        if (response.status === 200) {
          toast.success("Disable autostop successful");
          this.loadDeployments();
        } else {
          toast.error("Disable autostop error");
        }
      } catch (error) {
        toast.error(error.response.data);
        this.disableAutostopCheck = true;
      }
    },
    async changeImageStack(deployment) {
      try {
        if (this.newImage == null) {
          toast.error("Select new image");
          return;
        }
        deployment.stack.imageStack = this.newImage;
        const response = await this.$api.put(
          `/v1/deployment/changeimagestack`,
          deployment
        );
        if (response.status === 200) {
          toast.success("Change image stack successful");
          this.loadDeployments();
        } else {
          toast.error("Change image stack error");
        }
      } catch (error) {
        toast.error(error.response.data);
        this.disableAutostopCheck = true;
      }
    },
    async scale(deployment, replica) {
      try {
        const response = await this.$api.post(
          `/v1/deployment/scale?replica=${replica}`,
          deployment
        );
        if (response.status === 200) {
          this.loadDeployments();
          if (replica == 0) {
            toast.success("Stop successful");
            this.enableRefresh = false;
          } else {
            toast.success("Start successful");
            this.enableRefresh = true;
          }
        }
      } catch (error) {
        toast.error("start error");
      }
    },
    async loadImageStack() {
      this.$api
        .get("/v1/image")
        .then((response) => {
          this.imagesStacks = response.data;
        })
        .catch((error) => {
          console.error("Error fetching images:", error);
        });
    },
    async reconnectLoadDeployment() {
      if (this.reconnecting) return; // Avoid multiple reconnection attempts
      this.reconnecting = true;

      setTimeout(() => {
        if (this.socket) return; // If socket already exists, skip reconnection

        this.loadDeployments();
        this.reconnecting = false;
      }, 5000); // Retry after 5 seconds
    },
    async loadDeployments() {
      this.closeLoadDeployment(); // Ensure any previous socket is closed

      try {
        // Create WebSocket connection
        const socket = createWebSocket(
          `/v1/deployment?namespace=${this.namespace}`
        );

        this.socket = socket;

        // Handle incoming WebSocket messages
        this.socket.onmessage = (event) => {
          try {
            const response = JSON.parse(event.data);

            if (response.error) {
              console.error("WebSocket error:", response.error);
            } else if (response.data) {
              this.loading = false;
              this.deployments = response.data; // Reactive updates
            }
          } catch (e) {
            console.error("Failed to parse WebSocket message:", e.message);
            this.closeLoadDeployment();
          }
        };

        // Handle WebSocket close
        this.socket.onclose = (event) => {
          if (!event.wasClean) {
            console.warn("WebSocket closed unexpectedly.");
            this.reconnectLoadDeployment();
          }
        };

        // Handle WebSocket errors
        this.socket.onerror = (error) => {
          console.error("WebSocket error:", error);
          this.closeLoadDeployment();
        };
      } catch (err) {
        console.error("Failed to establish WebSocket connection:", err.message);
        this.closeLoadDeployment();
      }
    },
    async closeLoadDeployment() {
      if (this.socket) {
        this.socket.close();
        this.socket = null;
      }
      this.reconnecting = false; // Stop reconnection attempts
    },
  },
};
</script>
